var $=jQuery;
var DOMAIN = location.protocol + "//" + location.host;

// JS libraries included in plugins.min.js
$(window).setBreakPoints([320,480,768,1200]);

jQuery(document).ready(function($){

	initMobileNav();

	if (isPage('contact')){
		initContact();
	}

});

// =============================================================
// Mobile Navigation                                           =
// =============================================================
function initMobileNav(){

	var menuClose = 5;
	var menuOpen = ($('header nav a').height()+1) * $('header nav a').length;

	$('header .bars').click(function(){
		if ($(this).hasClass('open')){
			$('header nav').animate({height:menuClose}, {
				duration: 500,
				complete: function(){
					$('header .bars').removeClass('open');
				}
			});
		} else {
			$('header nav').css('padding-top',0).animate({height:menuOpen}, {
				duration: 500,
				complete: function(){
					$('header .bars').addClass('open');
				}
			});
		}
	});

	// Resize listener
	$(window).on('breakPointChange', function(){
		if ($(window).getBreakPoint() < 768){
			$('header .bars').show();
			menuOpen = ($('header nav a').height()+1) * $('header nav a').length;
		} else {
			$('header .bars').removeClass('open').hide();
			$('header nav').removeAttr('style');
		}
	});

}

// ===================================================================
// Function to initialize contact us form
// ===================================================================
function initContact(){

	// Validate
	$('#formContact').validate({
		errorPlacement: function (label, element) {
			$(element).parent().addClass("error");
			label.insertAfter($(element).parent());
		},
		errorClass : "error",
		success: function(label, element) {
			$(element).parent().removeClass("error");
			label.remove();
		},
		messages: {
				"name": "First name is required",
				"email": "Please enter a valid email address",
				"phone": "Please enter a valid phone number",
				"message": "Please provide us a brief message"
		},
		submitHandler: function(form) {
			var postData = $(form).serializeArray();
			postData.push({name:'hostname', value:document.location.hostname});

			// Google reCAPTCHA
			grecaptcha.ready(function(){
				grecaptcha.execute('6LftvmMaAAAAAAyXkiYZcvm6RJsjC1OTm8hvhSTd', {action: 'jotformSubmit'}).then(function(token){
					postData.push({name:'token', value:token});
					postData.push({name:'action', value:'jotformSubmit'});
					//console.log(postData);

					$.ajax({
						type: "POST",
						url: "../jotform-contact.php",
						data: postData,
						cache: false
					}).done(function(data){
						//console.log("ajax returned: "+data);
						$('#formContact input[type=text]').val('');
						$('#formContact input[type=email]').val('');
						$('#formContact textarea').val('');
						alert('Your contact request has been sent!');
					}).fail(function(xhr, textStatus, errorThrown){
						console.log("ajax failure: "+textStatus+"|"+errorThrown);
					});

				});
			});

		}
	});

}

// ===================================================================
// Global Function to determine what page is viewed based on body ID
// ===================================================================
function isPage(a){

	if($('body#'+a).length){return true;}
	else if ($('body').hasClass(a)){return true;}
	else if ($('body').hasClass('page-'+a)){return true;}
	else{return false;}

}